<template>
  <div class="not-found-container">
    <h3>Page not found.</h3>
    <br>
    <h3>Go home?</h3>
    <br>
    <dx-button type="default" text="Go home" @click="goHome"/>
  </div>

</template>

<script>
export default {
  setup() {
    const router = useRouter();


    const goHome = () => {
      router.push("/");
    };

    return {goHome}
  },

  components: {
    DxButton
  }
}
import { useRouter } from 'vue-router';

import DxButton from 'devextreme-vue/button'
</script>

<style lang="scss" scoped>
.not-found-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>